@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
      *{
        font-family: 'Source Sans Pro', sans-serif;
        margin:0px;
        position: relative;
        padding:0px;
      }
      .last_link{
        margin-right: 0 !important;
        text-decoration: none !important;
      }
      .slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;touch-action:pan-y;}.slick-list{overflow:hidden;margin:0;padding:0;height: 230px;}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block;height:300px;}.slick-vertical .slick-slide{display:block;height:auto;border:0px solid transparent}.slick-arrow.slick-hidden{display:none}

      .slick-dots,.slick-next,.slick-prev{position:absolute;display:block;padding:0}.slick-dots li button:before,.slick-next:before,.slick-prev:before{}.slick-loading .slick-list{background:#fff center center no-repeat #fff}.slick-next,.slick-prev{font-size:0;line-height:0;top:50%;width:70px;height:70px;-webkit-transform:translate(0,-50%);transform:translate(0,-50%);cursor:pointer;border:none;outline:0;background:0 0}.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{outline:0;background:0 0}.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{opacity:1}.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{opacity:.25}.slick-next:before,.slick-prev:before{font-size:20px;line-height:1;opacity:.75;}.slick-prev{left:-65px}[dir=rtl] .slick-prev{right:-25px;left:auto}.slick-prev:before{content:'←'}.slick-next:before,[dir=rtl] .slick-prev:before{content:'→'}.slick-next{right:-65px}[dir=rtl] .slick-next{right:auto;left:-25px}[dir=rtl] .slick-next:before{content:'←'}.slick-dotted.slick-slider{margin-bottom:30px;height:300px;}.slick-dots{bottom:-25px;width:100%;margin:0;list-style:none;text-align:center}.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 5px;padding:0;cursor:pointer}.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;border:0;outline:0;background:0 0}.slick-dots li button:focus,.slick-dots li button:hover{outline:0}.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}.slick-dots li button:before{font-size:6px;line-height:20px;position:absolute;top:0;left:0;width:20px;height:20px;content:'•';text-align:center;opacity:.25;}.slick-dots li.slick-active button:before{opacity:.75;}.slick-slide:nth-child(odd){border-right:2px solid #142153;}
      .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 3;
        stroke-miterlimit: 10;
        stroke: #000;
        fill: #fff;
        -webkit-animation: stroke .45s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
                animation: stroke .45s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
      }

      .checkmark__circle_home {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 3;
        stroke-miterlimit: 10;
        stroke: #F89C2E;
        fill: #fff;
        -webkit-animation: stroke .45s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
                animation: stroke .45s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
      }

      .checkmark {
        width: 106px;
        height: 106px;
        border-radius: 50%;
        display: block;
        stroke-width: 3;
        stroke: #F89C2E;
        stroke-miterlimit: 10;
        margin: 40px auto;
        box-shadow: inset 0px 0px 0px #000;
        -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .65s both;
                animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .65s both;
      }

      .checkmark__check {
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        -webkit-animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .50s forwards;
                animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .50s forwards;
      }

      @-webkit-keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }

      @-webkit-keyframes scale {
        0%, 100% {
          -webkit-transform: none;
                  transform: none;
        }
        50% {
          -webkit-transform: scale3d(1.1, 1.1, 1);
                  transform: scale3d(1.1, 1.1, 1);
        }
      }

      @keyframes scale {
        0%, 100% {
          -webkit-transform: none;
                  transform: none;
        }
        50% {
          -webkit-transform: scale3d(1.1, 1.1, 1);
                  transform: scale3d(1.1, 1.1, 1);
        }
      }



      .md-checkbox {
        position: relative;
        margin: 15px 0px !important;
        text-align: left;
        color:#142153;
        width: 250;
        left: 90px;
      }
      @media (max-width: 900px) {
        .md-checkbox {
          left: 0;
          text-align: center !important;
          margin:0 auto !important;
        }
      } 

      .md-checkbox label {
        cursor: pointer;
        color:#142153;
        padding-top: 3;
        padding-left: 35px;
      }
      .md-checkbox label:before, .md-checkbox label:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
      }
      .md-checkbox label:before {
        width: 22px;
        height: 22px;
        background: #fff;
        border: 2px solid #f89c2e;
        border-radius: 2px;
        cursor: pointer;
        transition: background 0.3s;
      }
      .md-checkbox input[type="checkbox"] {
        outline: 0;
        margin-right: 10px;
        visibility: hidden;
      }
      .md-checkbox input[type="checkbox"]:checked + label:before {
        background: #f89c2e;
        border: none;
        width: 27px;
        height: 27px;
      }
      .md-checkbox input[type="checkbox"]:checked + label:after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        top: 6px;
        left: 5px;
        width: 14px;
        height: 7px;
        border: 2px solid #fff;
        border-top-style: none;
        border-right-style: none;
      }
      h2, h1, h3{
        width:calc(100% - 350px);
        color:#243353;
        font-weight:400;
        font-size:32px;
        margin-bottom:20px;
      }
      p{
        width:calc(100% - 350px);
        letter-spacing:0.7;
        line-height:1.5;
        color:#243353;
        font-weight:300;
        font-size:19px;
        margin-bottom:30px;
      }
      @media screen and (max-width: 750px) {
        h2, h1, h3, p {
          width:100%;
        }
      }
      @media screen and (max-width: 570px) {
        h2, h1, h3{
          font-size:25px;
        }
        p{
          font-size:15px;
        }
      }
      @media screen and (max-width: 450px) {
        h2, h1, h3{
          font-size:22px;
        }
      }
.Content {
  white-space: pre-line;
}

.Content--Image {
  max-width: 100%;
  height: auto;
}

.Content--Iframe {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.Content--Iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.objava > .Content > h1, .objava > .Content > h2, .objava > .Content > h3 {
	display: none;
}

.objava > .Content > p{
	width: 100%;
	font-size: 18px;
	display: block;
	padding: 0;
	font-weight: 300;
	margin: 0;
	margin-bottom: 5px;
}

.objava > .Content{
	height:84px;
	overflow: hidden;
	margin-bottom: 20px;
}

@media (max-width: 450px) {
	.objava > .Content > p{
		width: 100%;
		font-size: 15px;
		display: block;
		padding: 0;
		font-weight: 300;
		margin: 0;
		margin-bottom: 5px;
	}

	.objava > .Content{
		height:89px;
		overflow: hidden;
		margin-bottom: 20px;
	}

}
.objava > .Content > h1, .objava > .Content > h2, .objava > .Content > h3 {
	display: none;
}

.objava > .Content > p{
	width: 100%;
	font-size: 18px;
	display: block;
	padding: 0;
	font-weight: 300;
	margin: 0;
	margin-bottom: 5px;
}

.objava > .Content{
	height:84px;
	overflow: hidden;
	margin-bottom: 20px;
}

@media (max-width: 450px) {
	.objava > .Content > p{
		width: 100%;
		font-size: 15px;
		display: block;
		padding: 0;
		font-weight: 300;
		margin: 0;
		margin-bottom: 5px;
	}

	.objava > .Content{
		height:89px;
		overflow: hidden;
		margin-bottom: 20px;
	}

}
