.objava > .Content > h1, .objava > .Content > h2, .objava > .Content > h3 {
	display: none;
}

.objava > .Content > p{
	width: 100%;
	font-size: 18px;
	display: block;
	padding: 0;
	font-weight: 300;
	margin: 0;
	margin-bottom: 5px;
}

.objava > .Content{
	height:84px;
	overflow: hidden;
	margin-bottom: 20px;
}

@media (max-width: 450px) {
	.objava > .Content > p{
		width: 100%;
		font-size: 15px;
		display: block;
		padding: 0;
		font-weight: 300;
		margin: 0;
		margin-bottom: 5px;
	}

	.objava > .Content{
		height:89px;
		overflow: hidden;
		margin-bottom: 20px;
	}

}